/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023 陕西丁甲数据科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : 权限过滤指令</li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年10月17日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import store from '@/store'

export default {
  mounted(el, {value}) {
    const {roleInfo, authorities} = store.getters
    if (typeof value === 'string') {
      value = [value];
    }
    //账套管理员
    if (!roleInfo.astAdmin && !value.every(val => authorities.includes(val))) {
      el.remove()
    }
  }
}
